import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from "styled-components"

const NavMain = styled.nav`
  padding-top: 10px !important;
  font-size: 16px;
  color: #1d1d1d;
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  display: block;
  width: 100%;
  padding: 0;
  background-color: #fff;
  z-index: 1000;
  top: 0;
  border-bottom: none;
  position: absolute;
  position: fixed;
  .nav__primary {
    font-size: 16px;
    color: #1d1d1d;
    -webkit-font-smoothing: antialiased;
    box-sizing: inherit;
    max-width: 72rem;
    margin: 0 auto;
    padding: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    .nav__logo {
      font-size: 16px;
      color: #1d1d1d;
      -webkit-font-smoothing: antialiased;
      box-sizing: inherit;
      display: flex;
      align-items: center;
      width: 140px;
    }
    .nav__list {
      color: #1d1d1d;
      -webkit-font-smoothing: antialiased;
      box-sizing: inherit;
      font-weight: 400;
      line-height: 1.44444444;
      letter-spacing: normal;
      font-size: 1.125rem;
      text-align: center;
      padding: 0;
      list-style: none;
      margin: 0;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      flex: 1;
      max-width: 700px;
      margin-left: 40px;
      .nav__listitem a {
        cursor: pointer;
        color: #1d1d1d;
        -webkit-font-smoothing: antialiased;
        list-style: none;
        box-sizing: inherit;
        font-weight: 600;
        line-height: 1.28571429;
        letter-spacing: normal;
        display: inline-block;
        margin: 10px;
        font-size: 1rem;
        width: 170px;
        :hover {
          color: #3370FF;
        }
      }
    }
  }
  @media screen and (max-width: 767px) and (min-width: 0) {
    .logo-img {
      width: 130px !important;
    }
    .nav_menu_btn {
      position: relative;
      top: 10px;
      height: 40px;
      width: 40px;
    }
    .nav_menu_btn svg {
      position: absolute;
      top: 10px;
      left: 6.5px;
    }
    .c-nav--primary {
      padding: 0;
      height: 70px;
      background-color: #fff;
      z-index: 1000;
      top: 0;
      position: fixed;
      border-bottom: none;
      width: 100%;
      display: block;
      .o-nav--primary {
        margin: 0 auto;
        padding: 0 8vw;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .c-slacklogo {
          display: flex;
          align-items: center;
          width: 125px;
        }
        .c-slacklogo a {
          line-height: inherit;
          display: inline-block;
          font-size: 0;
          padding: 0;
          border: none;
          background: 0 0;
          border: none;
          text-decoration: none;
          cursor: pointer;
          text-align: center;
          padding: 0;
        }
        header.nav-deux .c-nav__icon.v--menu {
          display: flex;
          align-items: center;
          position: initial;
          right: 2rem;
          top: 1.5rem;
          border-radius: 0 !important;
        }
      }
    }
    .c-nav__mobile.is-hidden {
      display: none !important;
      transition-duration: 80ms;
      transition-timing-function: linear;
    }
    .c-nav__mobile {
      display: flex;
      height: 700px;
      overflow: hidden;
      box-sizing: inherit;
      background-size: 1300px 700px;
      background-repeat: repeat;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      flex-direction: column;
      transform: translateX(0);
      z-index: 1000;
      background-color: #fff;
      background-image: none !important;
      padding: 0 1rem 1rem 0.5rem;
      transform: translateX(0);
      transition-duration: 80ms;
      transition-timing-function: linear;
      .c-nav__toolbar {
        padding: 1rem 3rem;
        padding-right: calc(3rem);
        margin: 0 -2rem 1rem;
        display: flex;
        align-items: center;
        height: 70px;
        justify-content: space-between;
      }
      .c-nav__logo {
        background: 0 0;
        color: #1264a3;
        cursor: pointer;
        text-decoration: none;
        word-break: break-word;
        border-bottom: none;
        width: 35%;
        margin-left: 0;
      }
      #nav_close_btn {
        -webkit-font-smoothing: antialiased;
        box-sizing: inherit;
        color: inherit;
        font: inherit;
        margin: 0;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: none;
        background: 0 0;
        border: none;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        border-radius: 4px;
        text-align: center;
        padding: 0;
      }
      .c-nav__scroller {
        overflow-y: scroll;
        padding-right: 0.5rem;
        position: absolute;
        height: 100%;
        width: 93%;
        top: 6rem;
        padding-bottom: 40vh;
        .c-nav__list {
          font-weight: 400;
          line-height: 1.44444444;
          letter-spacing: normal;
          font-size: 1.125rem;
          margin: 0 auto;
          padding: 0;
          list-style: none;
          text-align: left;
          flex-direction: column;
          display: flex;
          align-items: flex-start;
          height: auto;
          min-height: 72.5%;
          margin-bottom: 2rem;
          outline: 0;
          .c-nav--primary {
            font-size: 16px;
            display: block;
            width: 100%;
            padding: 0;
            height: 70px;
            background-color: #fff;
            z-index: 1000;
            top: 0;
            position: fixed;
            border-bottom: none;
          }
          .c-nav--primary__listitem {
            font-weight: 400;
            line-height: 1.28571429;
            letter-spacing: normal;
            display: inline-block;
            width: 100%;
            padding: 0.25rem 0 0.5rem 0.75rem;
            margin: 0.25rem 0;
            font-size: 1.1rem;
            a {
              font-weight: 400;
              line-height: 1.28571429;
              letter-spacing: normal;
              font-size: 1.1rem;
              box-sizing: inherit;
              background: 0 0;
              cursor: pointer;
              text-decoration: none;
              word-break: break-word;
              border: none;
              display: inline-flex;
              padding: 10px 0 0.25em !important;
              border-radius: 0 !important;
              margin: 0 0 0.25rem 10px !important;
              white-space: nowrap;
              border-bottom: 1px solid transparent !important;
              margin-left: 0.35em !important;
              color: #454545;
            }
          }
        }
      }
    }
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
      isHidden: "is-hidden",
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 20) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  openMenu = event => {
    this.setState({ isHidden: "" })
  }

  closeMenu = event => {
    this.setState({ isHidden: "is-hidden" })
  }

  render() {
    return (
      <header>
        <NavMain>
          <div
            className={
              this.state.hasScrolled ? "Header HeaderScrolled" : "Header"
            }
          >
            <div className="nav__primary">
              <div className="nav__logo">
                <Link to="/">
                  <img
                    className="logo-img"
                    src="/images/airbike-review-logo.svg"
                    alt="AirBikeReview.com - Home"
                    width="130px"
                  />
                </Link>
                <ul className="nav__list">
                  <li className="nav__listitem">
                    <Link to="/top-rated-air-bikes-2020">Top Air Bikes</Link>
                  </li>
                  <li className="nav__listitem">
                    <Link to="/rogue-echo-bike-review">Rogue Echo Bike</Link>
                  </li>
                  <li className="nav__listitem">
                    <Link to="/assault-airbike-review">Assault Airbike</Link>
                  </li>
                  <li className="nav__listitem">
                    <Link to="/schwinn-airdyne-pro-review">
                      Schwinn AirDyne Pro
                    </Link>
                  </li>
                  <li className="nav__listitem">
                    <Link to="/concept2-bike-erg-review">Concept2 BikeErg</Link>
                  </li>
                </ul>
              </div>
            </div>
            <nav className="c-nav--primary">
              <div className="o-nav--primary">
                <div className="c-slacklogo">
                  <a
                    data-clog-click=""
                    data-clog-ui-element="link_home"
                    data-clog-ui-component="inc_header_nav_deux"
                    className="c-slacklogo--white"
                    href="/"
                    data-qa="logo"
                  >
                    <img
                      className="logo-img"
                      src="/images/airbike-review-logo.svg"
                      alt="AirBikeReview.com - Home"
                      width="150px"
                    />
                  </a>
                </div>
                <button
                  onClick={this.openMenu}
                  id="nav_menu_btn"
                  aria-label="Menu"
                  className="nav_menu_btn c-nav__icon v--menu u-border-radius--none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="17"
                    viewBox="0 0 24 17"
                    aria-label=""
                    className="svg-replaced"
                    shapeRendering="geometricPrecision"
                  >
                    <path
                      d="M0 0h24v3H0zm0 7h24v3H0zm0 7h24v3H0z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </nav>
            <nav className={"c-nav__mobile " + this.state.isHidden}>
              <div className="c-nav__toolbar v--top">
                <a className="c-nav__logo" href="/">
                  <img
                    className="logo-img"
                    src="/images/airbike-review-logo.svg"
                    alt="AirBikeReview.com - Home"
                    width="150px"
                  />
                </a>
                <button
                  onClick={this.closeMenu}
                  id="nav_close_btn"
                  className="r-nav__icon v--close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-255 347 100 100"
                    width="18"
                    aria-label="Close Navigation"
                    className="svg-replaced"
                    shapeRendering="geometricPrecision"
                  >
                    <path d="M-160.4 434.2l-37.2-37.2 37.1-37.1-7-7-37.1 37.1-37.1-37.1-7 7 37.1 37.1-37.2 37.2 7.1 7 37.1-37.2 37.2 37.2"></path>
                  </svg>
                </button>
              </div>
              <div className="c-nav__scroller">
                <ul className="c-nav__list v--left v--main c-nav__tree bordered-links">
                  <li className="c-nav--primary__listitem">
                    <Link to="/top-rated-air-bikes-2020">Top Air Bikes</Link>
                  </li>
                  <li className="c-nav--primary__listitem">
                    <Link to="/rogue-echo-bike-review">Rogue Echo Bike</Link>
                  </li>
                  <li className="c-nav--primary__listitem">
                    <Link to="/assault-airbike-review">Assault Airbike</Link>
                  </li>
                  <li className="c-nav--primary__listitem">
                    <Link to="/schwinn-airdyne-pro-review">
                      Schwinn AirDyne Pro
                    </Link>
                  </li>
                  <li className="c-nav--primary__listitem">
                    <Link to="/concept2-bike-erg-review">Concept2 BikeErg</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </NavMain>
      </header>
    )
  }
}

export default Header
