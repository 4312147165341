import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const FooterContainer = styled.footer`
  cursor: default;
  list-style: none;
  box-sizing: border-box;
  display: block;
  position: relative;
  text-align: center !important;
  padding-bottom: 65px;
  padding-top: 70px;
  background-color: #ffffff !important;
  a {
    margin-right: 20px;
  }
  .footer-sub-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    max-width: 1170px;
    position: relative;
  }
  .row2 {
    width: 63%;
    text-align: justify;
    margin: auto;
  }
`
const NewFooter = () => (
  <FooterContainer>
    <div className="footer-sub-container">
      <div className="row1">
        <Link to="">Air Bike Reviews</Link>
        <Link to="">Rogue Echo Bike</Link>
        <Link to="">Assault Airbike</Link>
        <Link to="">Schwinn AirDyne</Link>
        <Link to="/concept2-bike-erg-review">Concept2 BikeErg</Link>
      </div>
      <div className="row2">
        <h3>About AirBikeReview.com</h3>
        <p>
          Feeling overwhelmed looking for the best Air Bike? Our goal with this
          website is to make it easier for you.
        </p>
        <p>
          We compile crucial info about popular Air Bike equipment, then assign
          a single rating out of 5 stars with our exclusive algorithm (based on
          100s of customer & expert reviews), all to save you time and money
          meeting your fitness goals.
        </p>
        <div>© {new Date().getFullYear()}, AirBikeReview.com</div>
      </div>
    </div>
  </FooterContainer>
)

export default NewFooter
